<template>
  <v-row>
    <v-col cols="12" md="12" v-if="getTotalFiles < getMaxLimit">
      <div
        class="py-5 upload-area text-center text-secondary"
        @click="handleClick"
      >
        <v-icon size="60">mdi-cloud-upload</v-icon>
        <div class="text-h5 text-secondary">Click here to select file</div>
        <p class="my-1 text-grey-700">
          Allowed ( {{ getAllowedFiles }} ). <br />
          Max size of 5 MB <br />
          Max File Allowed ({{ getTotalFiles }} / {{ getMaxLimit }})
        </p>
      </div>
      <input
        ref="refInputEl"
        type="file"
        name="file"
        multiple
        hidden
        @change="uploadFile"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      attachLoading: false,
      refInputEl: null,
      activeClass: "",
      files: [],
    };
  },
  props: {
    maxLimit: {
      type: Number,
      default: 10,
    },
    totalFiles: {
      type: Number,
      default: 10,
    },
    validFileTypes: {
      type: Array,
      default: () => {
        return [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "pdf",
          "docx",
          "doc",
          "xls",
          "xlsx",
        ];
      },
    },
  },
  methods: {
    handleClick() {
      this.$refs.refInputEl.click();
    },
    uploadFile(uploads) {
      const { files } = uploads.target;

      if (this.getAvailableLimit > 0) {
        Object.keys(files).forEach((key) => {
          const file = files[key];
          const file_name = file.name.split(".").slice(0, -1).join(".");
          const fileType = `${file.name.split(".").pop()}`;

          // Check if the file type is allowed
          if (this.isFileTypeAllowed(fileType)) {
            this.files.push({
              file: file,
              name: file_name,
              fileType: fileType,
            });
          } else {
            // Show an error or alert for disallowed file types
            // alert(`File type ${fileType} is not allowed.`);
          }
          if (this.files.length > this.getAvailableLimit) {
            return;
          }
        });
        this.$emit("update:file", this.files);
        this.$nextTick(() => {
          this.files = [];
          uploads.target.value = null;
        });
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    isFileTypeAllowed(fileType) {
      const allowedFileTypes = this.validFileTypes; //["pdf", "doc", "docx", "xls", "xlsx"];
      return allowedFileTypes.includes(fileType);
    },
  },
  computed: {
    getAllowedFiles() {
      const _validFiles = this.validFileTypes;
      return _validFiles.join(", ");
    },
    getTotalFiles() {
      return this.totalFiles;
    },
    getMaxLimit() {
      return this.maxLimit;
    },
    getAvailableLimit() {
      return this.maxLimit - this.totalFiles;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-area {
  border: 2px dashed #d6d6d7;
  cursor: pointer;
  border-radius: 4px;
  background: #f9f9f9;
}
.hidden {
  display: none;
}
</style>
