<template>
  <Dialog :commonDialog="documentDialog">
    <template v-slot:title> Upload Document</template>
    <template v-slot:body>
      <v-form
        v-if="false"
        ref="documentForm"
        v-model.trim="export_valid"
        lazy-validation
        v-on:submit.stop.prevent="submitDocument()"
      >
        <table style="table-layout: fixed" class="table-document" width="100%">
          <template v-for="(file, index) in files">
            <tr
              :key="`file-row-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <!-- <td width="45%" class="px-2">
                  <v-file-input
                    hide-details
                    class="px-0"
                    dense
                    filled
                    solo
                    flat
                    v-model="file.file"
                    placeholder="Select File"
                    prepend-icon=""
                    prepend-inner-icon="$file"
                    v-on:change="updateFile(index, $event)"
                    v-on:click:clear="updateFile(index, $event)"
                    color="cyan"
                  />
                </td> -->
              <td width="90%" colspan="2" class="px-2">
                <div class="d-flex align-center">
                  <div style="max-width: 40px; min-width: 40px; width: 40px">
                    <inline-svg
                      style="max-width: 40px; width: 40px"
                      :src="$assetURL(`media/mime/${file.suffix}.svg`)"
                    />
                  </div>
                  <TextInput
                    hide-details
                    hideTopMargin
                    placeholder="File Name*"
                    v-model="file.name"
                    :suffix="`${file.suffix ? file.suffix : ''} ${
                      file.name ? file.name.length : 0
                    }/50`"
                  ></TextInput>
                </div>
                <!-- <v-text-field
                    hide-details
                    class="px-0"
                    v-model="file.name"
                    placeholder="File Name*"
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                  /> -->
              </td>
              <td
                width="10%"
                align="center"
                valign="middle"
                class="px-2"
                :rowspan="extensive ? 4 : 1"
              >
                <v-btn
                  v-on:click="removeFile(index)"
                  color="red lighten-1 white--text"
                  class=""
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr
              v-if="extensive"
              :key="`file-date-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td width="45%" class="px-2">
                <!-- <DatePicker
                                      placeholder="Start Date"
                                      id="export-from-date"
                                      v-model="file.start_date"
                                      :min-date="currentDate"
                                  ></DatePicker> -->
                <DatePicker
                  solo
                  :nullable="true"
                  placeholder="Start Date"
                  id="export-from-date"
                  v-model="file.start_date"
                  :min-date="currentDate"
                ></DatePicker>
              </td>
              <td width="45%" class="px-2">
                <DatePicker
                  solo
                  :nullable="true"
                  placeholder="End Date"
                  :pageLoading="pageLoading"
                  v-model="file.end_date"
                  :min-date="file.start_date"
                ></DatePicker>
              </td>
            </tr>
            <tr
              v-if="extensive"
              :key="`file-reminder-date-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td width="90%" colspan="2" class="px-2">
                <DatePicker
                  solo
                  placeholder="Reminder Date"
                  id="export-from-date"
                  :nullable="true"
                  v-model="file.reminder_date"
                ></DatePicker>
              </td>
            </tr>
            <tr
              v-if="extensive"
              :key="`file-tag-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td width="90%" colspan="2" class="px-2">
                <v-combobox
                  id="tags"
                  height="15px"
                  hide-details
                  v-model="file.tags"
                  class="combobox unsetMaxHeight removeShadow"
                  :filter="filterTag"
                  :hide-no-data="!search"
                  solo
                  :items="items"
                  :search-input.sync="file.search"
                  item-text="tag"
                  item-value="id"
                  hide-selected
                  multiple
                  small-chips
                  dense
                  label="Search Tag"
                  placeholder="Search Tag"
                  v-on:keyup="(e) => checkTag(e, index)"
                >
                  <template v-slot:no-data>
                    <v-chip label class="my-2 mx-2" small>
                      {{ search }}
                    </v-chip>
                  </template>
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      class="my-1"
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.tag }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                  <v-list-item class="my-2 white" v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip class="mb-1" label small>
                          {{ item.tag }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-combobox>
              </td>
            </tr>
            <tr
              v-if="extensive"
              :key="`file-remark-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td colspan="2" width="90%" class="px-2 py-3">
                <v-textarea
                  v-model="file.remark"
                  auto-grow
                  dense
                  filled
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  placeholder="Description"
                  solo
                  flat
                  v-on:keypress="(e) => manageLimit(e, index)"
                  v-on:paste="(e) => onPaste(e, index)"
                  row-height="15"
                ></v-textarea>

                <TextAreaInput
                  v-if="false"
                  :rows="2"
                  dense
                  :id="`document-remark-${index}`"
                  hide-details
                  v-model="file.remark"
                  placeholder="Enter remark..."
                  :disabled="attachLoading"
                  :loading="attachLoading"
                  v-on:keypress="(e) => manageLimit(e, index)"
                  v-on:paste="(e) => onPaste(e, index)"
                ></TextAreaInput>
                <v-col md="12" class="text-right"
                  >{{ file.remark ? file.remark.length : 0 }}/200</v-col
                >
              </td>
            </tr>
          </template>
        </table>
        <v-btn
          v-if="false"
          color="cyan white--text"
          class="mt-2 ml-4"
          tile
          depressed
          v-on:click="addMore()"
        >
          <v-icon>mdi-plus</v-icon> Add More...
        </v-btn>
      </v-form>
      <div class="px-4">
        <AttachFile
          v-if="false"
          @update:file="addMoreFile"
          :validFileTypes="fileTypes"
          :max-limit="maxLimit"
          :total-files="files.length"
        ></AttachFile>
        <v-form
          ref="documentForm"
          v-model.trim="export_valid"
          lazy-validation
          v-on:submit.stop.prevent="submitDocument()"
        >
          <FileUpload v-model="files" :maxLimit="maxLimit"> </FileUpload>
        </v-form>
      </div>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!export_valid || attachLoading"
        :loading="attachLoading"
        class="white--text"
        depressed
        color="cyan"
        tile
        v-on:click="submitDocument()"
      >
        Submit
      </v-btn>
      <v-btn
        depressed
        tile
        :disabled="attachLoading"
        :loading="attachLoading"
        v-on:click="$emit('close', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
//import Dialog from "@/view/components/Dialog.vue";
//import TextInput from "@/view/pages/TextInput";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/pages/TextAreaInput";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ApiService from "@/core/services/api.service";
//import { SET_ERROR } from "@/core/services/store/common.module";

import DatePicker from "@/view/pages/partials/Datepicker.vue";
import MomentJS from "moment-timezone";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileUpload from "@/view/components/app-component/SingleFileInput.vue";
import AttachFile from "@/view/components/AttachFile";
export default {
  mixins: [ValidationMixin],
  name: "export-dialog",
  data() {
    return {
      fileIds: [],
      export_valid: true,
      attachLoading: false,
      files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      customer: null,
      currentDate: MomentJS().format("YYYY-MM-DD"),
      search: null,
      items: [],
      pageLoading: false,
    };
  },
  props: {
    moduleType: {
      type: String,
      default: null,
    },
    documentDialog: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: Number,
      default: null,
    },
    documentId: {
      type: Number,
      default: null,
    },
    parentType: {
      type: String,
      default: null,
    },
    parentTypeId: {
      type: Number,
      default: null,
    },
    maxLimit: {
      type: Number,
      default: 5,
    },
    fileTypes: {
      type: Array,
      default: () => {
        return [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "pdf",
          "docx",
          "doc",
          "xls",
          "xlsx",
        ];
      },
    },
    extensive: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    documentDialog(param) {
      this.files = [];
      if (param) {
        this.customer = null;
        this.files = [
          // {
          //   file: null,
          //   name: null,
          //   remark: null,
          //   suffix: null,
          //   start_date: null,
          //   end_date: null,
          //   reminder_date: null,
          // },
        ];
      }
    },
  },
  methods: {
    checkTag(e, index) {
      console.log(e);
      if (e.key == "Enter") {
        this.files[index].tags = this.files[index].tags.map((v) => {
          if (typeof v === "string") {
            v = {
              tag: v,
            };
            this.items.push(v);
          }

          return v;
        });
      }
    },
    /* manageLimit(e, index) {
        if (this.files[index].remark && this.files[index].remark.length > 199) {
          e.preventDefault();
        }
      }, */

    manageLimit(e, index) {
      if (this.files[index].remark && this.files[index].remark.length > 199) {
        e.preventDefault();
      }
    },
    onPaste(e, index) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.files[index].remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.files[index].remark = trimValue;
        e.preventDefault();
      }
    },

    addMore() {
      this.files.push({
        file: null,
        name: null,
        remark: null,
        suffix: null,
      });
    },
    /* addMoreFile($event) {
        const _files = $event;
        if (Array.isArray(_files)) {
          _files.forEach((element) => {
            this.files.push({
              file: element.file,
              name: element.name,
              remark: null,
              suffix: element.fileType,
              start_date: null,
              end_date: null,
              reminder_date: null,
            });
          });
        }
      }, */
    submitDocument() {
      const _this = this;

      // const formErrors = _this.validateForm(_this.$refs.documentForm);

      if (!_this.$refs.documentForm.validate()) {
        return false;
      }

      // if (formErrors.length) {
      // 	_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
      // 	return false;
      // }

      if (!_this.$refs.documentForm.validate()) {
        return false;
      }

      const params = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file && this.files[i].name) {
          params.append(`file[${i}][file]`, this.files[i].file);
          params.append(`file[${i}][name]`, this.files[i].name);
          if (this.files[i].remark) {
            params.append(`file[${i}][remark]`, this.files[i].remark);
          }
          if (this.files[i].start_date && this.files[i].end_date) {
            params.append(`file[${i}][start_date]`, this.files[i].start_date);
            params.append(`file[${i}][end_date]`, this.files[i].end_date);
          }
          if (this.files[i].reminder_date) {
            params.append(
              `file[${i}][reminder_date]`,
              this.files[i].reminder_date
            );
          }
          if (this.files[i].tags && this.files[i].tags.length > 0) {
            for (let t = 0; t < this.files[i].tags.length; t++) {
              if (this.files[i].tags[t].tag) {
                params.append(
                  `file[${i}][tags][${t}]`,
                  this.files[i].tags[t].tag
                );
              }
            }
          }
        }
      }

      params.append("type_id", this.documentId);
      params.append("type", this.documentType);
      params.append("module_type", this.moduleType);

      if (this.parentType && this.parentTypeId && this.parentTypeId > 0) {
        params.append("parent_type", this.parentType);
        params.append("parent_type_id", this.parentTypeId);
      }

      //if(!params.file){
      // this.$emit("error", "File name required");
      //this.logError("File name required");
      //_this.$store.commit(SET_ERROR, "File name required");
      // return false;
      //}

      this.attachLoading = true;

      ApiService.setHeader();
      ApiService.upload("all-documents", params)
        .then((data) => {
          //console.log(data,'result');
          if (data) {
            //console.log();
            this.fileIds = data.data.data;
            _this.$emit("getUploadedIds", this.fileIds);
          }
          this.$emit("success", true);
          this.$emit("close", true);
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
        });
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.files[index].name = null;
        this.files[index].suffix = null;
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      // if (this.files.length < 1) {
      //   this.addMore();
      // }
    },
    getTags() {
      const _this = this;
      ApiService.setHeader();
      ApiService.get("setting/all-tags").then((res) => {
        _this.items = res.data && res.data.data ? res.data.data : [];
      });
    },
    filterTag(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
  components: {
    // SET_ERROR,
    Dialog,
    TextInput,
    //AutoCompleteInput,
    TextAreaInput,
    DatePicker,
    AttachFile,
    FileUpload,
  },
  mounted() {
    this.getTags();
    //console.log(this.moduleType);
  },
  computed: {
    ...mapGetters(["errors", "localDB"]),
  },
};
</script>
<style scoped>
/* .v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 100%;
  } */

/* .v-input input {
    max-height: 30px;
  } */
.removeShadow > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  /*  max-height: 18px; */
  padding: 0;
}
.v-application .py-3 {
  /* padding-top: 12px !important; */
  padding-bottom: 0px !important;
}
.text-right.col-md-12.col {
  margin: -10px;
}
</style>
